import React, { useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import BottomWaves from "../assets/svg/bottomWaves.svg";



const Footer = (props) => {
    
    return(
        <div className="footer w-full h-[auto] py-[5%] relative overflow-hidden">


            <div className="footerContent md:flex-row flex-col">
                <div className="social md:w-[30%] w-full items-center md:items-start">
                    <h3>Follow us</h3>
                    <a href="https://www.instagram.com/aquaduo.water/"> Instagram</a>
                    <a href="https://www.facebook.com/AquaDuo-105078221816900/">Facebook</a>
                </div>
                {/* <div className="footerLinks">
                    <a>Home</a>
                    <a>Filters</a>
                    <a>About</a>
                </div> */}
                <div className="contact md:w-[30%] w-full items-center md:items-end mt-[20%] md:mt-0">
                    <h3>Email</h3>
                    <p>contact@aqua-duo.com</p>
                    <h3>Phone Number</h3>
                    <a>+915 525 8226</a>
                </div>
            </div>
            <p className="footerCopyright">© AquaDuo 2021</p>


            <span className="absolute bottom-0   z-[25]">
                <BottomWaves/>
            </span>
            <div className="footerBackground1 absolute bottom-0 z-20 w-full h-[100%]"></div>
            <div className="footerBackground2 absolute bottom-0 z-10 w-full h-[100%]"></div>
        </div>
    )
}
export default Footer