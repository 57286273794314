/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

import Div100vh from 'react-div-100vh'




const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

 
  return (
    <>
      <Div100vh>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className='mainContainer align-center items-center flex-col'>
          <main>{children}</main>
          <Footer/>
        </div>
      </Div100vh>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
