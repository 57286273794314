import * as React from 'react';
import Button from '@mui/material/Button';
import { AnchorLink } from "gatsby-plugin-anchor-links";



const CtaButtonStyle ={
  borderRadius:'20.5px',
  backgroundColor: '#212121',
  padding:'.5rem 2rem .5rem 2rem',
  boxShadow:'none',
  color:'#fafafa',
  transition:'all 0.2s',
  textTransform:'none',
  '&:hover': {
    color: '#fafafa',
  }
}

const CtaButtonStyleScrolled ={
  borderRadius:'20.5px',
  backgroundColor:'#45BAE2',
  padding:'.5rem 2rem .5rem 2rem',
  boxShadow:'none',
  color:'#3b3e42',
  transition:'all 0.2s',
  textTransform:'none',
  '&:hover': {
    color: '#fafafa',
  }
}

const CtaButton = (props) => {
  return (
    <AnchorLink  to={props.href} title={props.title}>
      <Button  disableElevation disableFocusRipple disableRipple sx={props.scrolled?CtaButtonStyleScrolled:CtaButtonStyle} variant="contained">{props.text.toString()}</Button>
    </AnchorLink>
    
  );
}
export default CtaButton