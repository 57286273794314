import React, { useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";



const CustomLink = (props) => {
    
    return(
        <AnchorLink className={`customlink ${props.className}`} to={props.href} title={props.title}>
            {props.text.toString()}
        </AnchorLink>
    )
}
export default CustomLink