
import React,{ useState, useEffect } from "react"
const isWindowAvailable = typeof window !== "undefined"

const getPosition = () => isWindowAvailable ? window.pageYOffset : undefined

const ScrollHandler = () => {

  const [scrollPosition, setScrollPosition] = useState(getPosition())

  useEffect(() => {
    if (!isWindowAvailable) {
      return false
    }

    const handleScroll = () => {
      setScrollPosition(getPosition())
    }

    window.addEventListener("scroll", handleScroll, {passive: true})

    return () => window.removeEventListener("scroll", handleScroll, {passive: true})
  }, [])

  return scrollPosition
}

export default ScrollHandler